import React from 'react';
import styled from 'styled-components';
import {message} from 'antd';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {getFormStageLabel} from '../../Utils/SelectOptionLabelUtil';
import {showAdminDownloadExcelModal} from '../../Components/AdminDownloadExcelModal';
import {dtStrExp} from '../../Utils/TimeUtil';
import ExportHelper from '../../TbaExportHelper';

const statusLabel = (status) => {
  switch (status) {
    case 'pending':
      return '待簽核';
    case 'success':
      return '通過';
    case 'abort':
      return '退簽';
    default:
      return status;
  }
};

const receivesRelatedDisplay = (helper, receives_related) => {
  let text = '';

  if (Array.isArray(receives_related) && receives_related.length > 0) {
    for (let i = 0; i < receives_related.length; i++) {
      const receive = helper.getReceiveRelated(receives_related[i]);

      if (receive && receive.paperwork_id) {
        text += `${receive.paperwork_id}\n`;
      }
    }
  }

  return text;
};

function transformQuery(queryParams) {
  const query = {
    type: 'post',
  };

  if (queryParams.start_date) {
    if (!dtStrExp.test(queryParams.start_date)) {
      message.warning('發文開始日期格式錯誤');
      return;
    }

    query.date = {
      $gte: queryParams.start_date,
    };
  }

  if (queryParams.end_date) {
    if (!dtStrExp.test(queryParams.end_date)) {
      message.warning('發文結束日期格式錯誤');
      return;
    }

    if (query.date) {
      query.date = {
        ...query.date,
        $lt: queryParams.end_date,
      };
    } else {
      query.date = {
        $lt: queryParams.end_date,
      };
    }
  }

  if (queryParams.text_number_start) {
    query.text_number = {
      $gte: queryParams.text_number_start,
    };
  }

  if (queryParams.text_number_end) {
    if (query.text_number) {
      query.text_number = {
        ...query.text_number,
        $lt: queryParams.text_number_end,
      };
    } else {
      query.text_number = {
        $lt: queryParams.text_number_end,
      };
    }
  }

  return query;
}

function AdminPostExport(props) {
  return (
    <Wrapper>
      <GenericForm
        instance={null}
        schema={{
          title: '',
          type: 'object',
          properties: {
            title: {
              type: 'string',
              title: '報表名稱',
              default: '發文簿',
            },
            start_date: {
              type: 'string',
              title: '發文日期（起）',
            },
            end_date: {
              type: 'string',
              title: '發文日期（迄）',
            },
            text_number_start: {
              type: 'string',
              title: '發文字號（起）',
            },
            text_number_end: {
              type: 'string',
              title: '發文字號（迄）',
            },
          },
        }}
        uiSchema={{
          start_date: {
            'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
          },
          end_date: {
            'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
          },
        }}
        onSubmit={(formData) => {
          let query = transformQuery(formData);

          const excelProps = {
            collection: 'form',
            filename: `${formData.title || '發文簿'}.xlsx`,
            query,
            recordsToAoa: async (records) => {
              const helper = new ExportHelper({
                receiveRelatedIds: (() => {
                  const ids = [];
                  const receive_list = records.map((r) => r.receives_related);

                  for (const arrayReceive of receive_list) {
                    if (
                      Array.isArray(arrayReceive) &&
                      arrayReceive.length > 0
                    ) {
                      for (const itemReceive of arrayReceive) {
                        if (itemReceive !== null && itemReceive !== '') {
                          ids.push(itemReceive);
                        }
                      }
                    }
                  }

                  return ids;
                })(),
              });

              await helper.fetchAll();

              return [
                ['發文列表', '', '', '', '', '', '', ''],
                [
                  '發文字號',
                  '發文日期',
                  '受文者',
                  '文別',
                  '副本',
                  '附件',
                  '主旨',
                  '收文編號',
                ],
                ...records.map((record) => [
                  record.paperwork_id,
                  record.date,
                  record.addressee,
                  record.category,
                  '',
                  '',
                  record.title,
                  `${receivesRelatedDisplay(helper, record.receives_related)}`,
                ]),
              ];
            },
            updateWorkSheet: (ws) => {
              //title
              ws['!merges'] = [{s: {r: 0, c: 0}, e: {r: 0, c: 7}}];
              const titles = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1'];

              for (let i = 0; i < titles.length; i++) {
                const cell = titles[i];

                if (i === 0) {
                  ws[cell].s = {
                    sz: 22,
                    alignment: {
                      horizontal: 'center',
                      vertical: 'center',
                    },
                    top: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                    left: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                  };
                } else if (i + 1 === titles.length) {
                  ws[cell].s = {
                    top: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                    right: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                  };
                } else {
                  ws[cell].s = {
                    top: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                  };
                }
              }

              //header
              const headers = ['A2', 'B2', 'C2', 'D2', 'E2', 'F2', 'G2', 'H2'];
              for (let i = 0; i < headers.length; i++) {
                const header = headers[i];

                ws[header].s = {
                  bold: true,
                  alignment: {
                    horizontal: 'center',
                  },
                  top: {
                    style: 'thin',
                    color: {rgb: '000000'},
                  },
                  bottom: {
                    style: 'thin',
                    color: {rgb: '000000'},
                  },
                  left: {
                    style: 'thin',
                    color: {rgb: '000000'},
                  },
                  right: {
                    style: 'thin',
                    color: {rgb: '000000'},
                  },
                };
              }

              //cell style
              const cells = Object.keys(ws);
              const excludeCells = [
                '!cols',
                '!merges',
                '!ref',
                ...titles,
                ...headers,
              ];

              for (let i = 0; i < cells.length; i++) {
                const cell = cells[i];

                if (!excludeCells.includes(cell)) {
                  ws[cell].s = {
                    ...ws[cell].s,
                    alignment: {
                      wrapText: true,
                    },
                    top: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                    bottom: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                    left: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                    right: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                  };
                }
              }

              //col width
              ws['!cols'] = [
                {
                  wpx: 78,
                },
                {
                  wpx: 102,
                },
                {wpx: 133},
                {wpx: 93},
                {wpx: 69},
                {wpx: 69},
                {wpx: 245},
                {wpx: 78},
              ];
            },
          };

          showAdminDownloadExcelModal({...excelProps});
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default AdminPostExport;
