import React from 'react';
import styled from 'styled-components';
import {message} from 'antd';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {getFormStageLabel} from '../../Utils/SelectOptionLabelUtil';
import {showAdminDownloadExcelModal} from '../../Components/AdminDownloadExcelModal';
import {dtStrExp} from '../../Utils/TimeUtil';
import ExportHelper from '../../TbaExportHelper';

const statusLabel = (status) => {
  switch (status) {
    case 'pending':
      return '待簽核';
    case 'success':
      return '通過';
    case 'abort':
      return '退簽';
    default:
      return status;
  }
};

const historyDisplay = (history) => {
  let text = '';

  if (Array.isArray(history) && history.length > 0) {
    text = history[history.length - 1].name;
  }

  return text;
};

const executionDisplay = (execution) => {
  let text = '';

  if (Array.isArray(execution) && execution.length > 0) {
    text = execution[execution.length - 1].name;
  }

  return text;
};

const postsExecutionDisplay = (helper, posts_execution) => {
  let text = '';

  if (Array.isArray(posts_execution) && posts_execution.length > 0) {
    for (let i = 0; i < posts_execution.length; i++) {
      const post = helper.getPostExecution(posts_execution[i]);

      if (post && post.text_number) {
        text += `${post.text_number}\n`;
      }
    }
  }

  return text;
};

function transformQuery(queryParams) {
  const query = {
    type: 'receive',
  };

  if (queryParams.start_date) {
    if (!dtStrExp.test(queryParams.start_date)) {
      message.warning('收文開始日期格式錯誤');
      return;
    }

    query.date = {
      $gte: queryParams.start_date,
    };
  }

  if (queryParams.end_date) {
    if (!dtStrExp.test(queryParams.end_date)) {
      message.warning('收文結束日期格式錯誤');
      return;
    }

    if (query.date) {
      query.date = {
        ...query.date,
        $lt: queryParams.end_date,
      };
    } else {
      query.date = {
        $lt: queryParams.end_date,
      };
    }
  }

  if (queryParams.paperwork_id_start) {
    query.paperwork_id = {
      $gte: queryParams.paperwork_id_start,
    };
  }

  if (queryParams.paperwork_id_end) {
    if (query.paperwork_id) {
      query.paperwork_id = {
        ...query.paperwork_id,
        $lt: queryParams.paperwork_id_end,
      };
    } else {
      query.paperwork_id = {
        $lt: queryParams.paperwork_id_end,
      };
    }
  }

  return query;
}

function AdminReceiveExport(props) {
  return (
    <Wrapper>
      <GenericForm
        instance={null}
        schema={{
          title: '',
          type: 'object',
          properties: {
            title: {
              type: 'string',
              title: '報表名稱',
              default: '收文簿',
            },
            start_date: {
              type: 'string',
              title: '收文日期（起）',
            },
            end_date: {
              type: 'string',
              title: '收文日期（迄）',
            },
            paperwork_id_start: {
              type: 'string',
              title: '收文編號（起）',
            },
            paperwork_id_end: {
              type: 'string',
              title: '收文編號（迄）',
            },
          },
        }}
        uiSchema={{
          start_date: {
            'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
          },
          end_date: {
            'ui:help': '格式為 yyyy-mm-dd，例如: 2024-01-01',
          },
        }}
        onSubmit={(formData) => {
          let query = transformQuery(formData);

          const excelProps = {
            collection: 'form',
            filename: `${formData.title || '收文簿'}.xlsx`,
            query,
            recordsToAoa: async (records) => {
              const helper = new ExportHelper({
                postExecutionIds: (() => {
                  const ids = [];
                  const post_list = records.map((r) => r.posts_execution);

                  for (const arrayPost of post_list) {
                    if (Array.isArray(arrayPost) && arrayPost.length > 0) {
                      for (const itemPost of arrayPost) {
                        ids.push(itemPost);
                      }
                    }
                  }

                  return ids;
                })(),
              });

              await helper.fetchAll();

              return [
                ['發文列表', '', '', '', '', '', '', '', '', '', ''],
                [
                  '收文編號',
                  '收文日期',
                  '發文機關',
                  '文別',
                  '來文字號',
                  '主旨',
                  '狀態',
                  '擬辦',
                  '簽核',
                  '執行',
                  '執行發文',
                ],
                ...records.map((record) => [
                  `${record.paperwork_id || ''}`,
                  `${record.date || ''}`,
                  `${record.organs || ''}`,
                  `${record.category || ''}`,
                  `${record.text_number || ''}`,
                  `${record.title || ''}`,
                  `${getFormStageLabel(record.stage)}`,
                  `${record.implement_name || ''}`,
                  `${historyDisplay(record.history)}`,
                  `${executionDisplay(record.history_execution)}`,
                  `${postsExecutionDisplay(helper, record.posts_execution)}`,
                ]),
              ];
            },
            updateWorkSheet: (ws) => {
              ws['!merges'] = [{s: {r: 0, c: 0}, e: {r: 0, c: 10}}];

              //title
              const titles = [
                'A1',
                'B1',
                'C1',
                'D1',
                'E1',
                'F1',
                'G1',
                'H1',
                'I1',
                'J1',
                'K1',
              ];

              for (let i = 0; i < titles.length; i++) {
                const cell = titles[i];

                if (i === 0) {
                  ws[cell].s = {
                    sz: 22,
                    alignment: {
                      horizontal: 'center',
                      vertical: 'center',
                    },
                    top: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                    left: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                  };
                } else if (i + 1 === titles.length) {
                  ws[cell].s = {
                    top: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                    right: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                  };
                } else {
                  ws[cell].s = {
                    top: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                  };
                }
              }

              //header
              const headers = [
                'A2',
                'B2',
                'C2',
                'D2',
                'E2',
                'F2',
                'G2',
                'H2',
                'I2',
                'J2',
                'K2',
              ];

              for (let i = 0; i < headers.length; i++) {
                const header = headers[i];

                ws[header].s = {
                  sz: 12,
                  bold: true,
                  alignment: {
                    horizontal: 'center',
                  },
                  top: {
                    style: 'thin',
                    color: {rgb: '000000'},
                  },
                  bottom: {
                    style: 'thin',
                    color: {rgb: '000000'},
                  },
                  left: {
                    style: 'thin',
                    color: {rgb: '000000'},
                  },
                  right: {
                    style: 'thin',
                    color: {rgb: '000000'},
                  },
                };
              }

              //cell style
              const cells = Object.keys(ws);
              const excludeCells = [
                '!cols',
                '!merges',
                '!ref',
                ...titles,
                ...headers,
              ];

              for (let i = 0; i < cells.length; i++) {
                const cell = cells[i];

                if (!excludeCells.includes(cell)) {
                  ws[cell].s = {
                    ...ws[cell].s,
                    sz: 12,
                    alignment: {
                      wrapText: true,
                      vertical: 'center',
                    },
                    top: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                    bottom: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                    left: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                    right: {
                      style: 'thin',
                      color: {rgb: '000000'},
                    },
                  };
                }
              }

              //col width
              ws['!cols'] = [
                {wpx: 104},
                {wpx: 107},
                {wpx: 115},
                {wpx: 132},
                {wpx: 169},
                {wpx: 184},
                {wpx: 91},
                {wpx: 98},
                {wpx: 99},
                {wpx: 107},
                {wpx: 134},
              ];

              //row height
              ws['!rows'] = [{hpx: 40}];

              console.log('DBG ws', ws);
            },
          };

          showAdminDownloadExcelModal({...excelProps});
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default AdminReceiveExport;
